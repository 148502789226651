import { paths } from 'src/routes/paths';

import { useLocales } from 'src/locales';
import { ListOfPermissionsAssignedToUser } from 'src/redux/coreApi';

import Label from 'src/components/label';
import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  campaigns: icon('ic_cart'),
  dashboard: icon('ic_dashboard'),
  chat: icon('ic_chat'),
  news: icon('ic_news'),
  user: icon('ic_user'),
  brands: icon('ic_label'),
  clubs: icon('ic_club'),
};

type NavItem = {
  title: string;
  path: string;
  icon: any;
  info?: any;
};

const userLevel = localStorage.getItem('userLevel') ?? '';
const isSecondary = userLevel === 'secondary';

export const useNavData = (
  usersToValidate: number,
  runningCampaigns: number,
  newMessages: number,
  permissions: ListOfPermissionsAssignedToUser = {}
) => {
  const { translate } = useLocales();

  const managementItems = useManagementItems(usersToValidate, runningCampaigns, permissions);

  const chatItem = useChatItems(newMessages, permissions);

  if (!isSecondary) {
    const canCreateCampaign = permissions.permissions?.some(
      ({ key = '', action }) =>
        ['MANAGE_ALL', 'STEP_ONE_SET_UP_CAMPAIGNS'].includes(key) && action === 'allow'
    );

    const generalItems: NavItem[] = [
      {
        title: translate('dashboard'),
        path: paths.dashboard.root,
        icon: ICONS.dashboard,
      },
    ];

    if (canCreateCampaign) {
      generalItems.push({
        title: translate('create_campaign'),
        path: paths.dashboard.campaigns.new,
        icon: ICONS.campaigns,
      });
    }

    return [
      {
        subheader: translate('general'),
        items: generalItems,
      },
      {
        subheader: translate('management'),
        items: managementItems,
      },
      {
        subheader: translate('communication'),
        items: chatItem,
      },
    ];
  }

  return [
    {
      subheader: translate('general'),
      items: [
        {
          title: translate('dashboard'),
          path: paths.dashboard.root,
          icon: ICONS.dashboard,
        },
      ],
    },
    {
      subheader: translate('management'),
      items: managementItems,
    },
    {
      subheader: translate('communication'),
      items: chatItem,
    },
  ];
};

const useManagementItems = (
  usersToValidate: number,
  runningCampaigns: number,
  permissions: ListOfPermissionsAssignedToUser = {}
) => {
  const { translate } = useLocales();

  const {
    permissions: userPermissions,
    campaigns: campaignsPermissions,
    brands: brandsPermissions,
  } = permissions;

  const canSeeUsers = userPermissions?.some(
    ({ key = '', action }) => ['MANAGE_ALL', 'SEE_USERS'].includes(key) && action === 'allow'
  );

  const canSeeClubs = userPermissions?.some(
    ({ key = '', action }) => ['MANAGE_ALL', 'SEE_CLUBS'].includes(key) && action === 'allow'
  );

  const canSeeBrands = brandsPermissions?.some(
    ({ controls = [] }) => controls.includes('SEE_BRAND') || controls.includes('ALL')
  );

  const canSeeCampaigns =
    campaignsPermissions?.some(
      ({ controls = [] }) => controls.includes('SEE_CAMPAIGN') || controls.includes('ALL')
    ) ?? false;

  const items: NavItem[] = [];

  if (canSeeUsers) {
    items.push({
      title: translate('users'),
      path: paths.dashboard.users.root,
      icon: ICONS.user,
      info: usersToValidate > 0 ? <Label color="error">{usersToValidate}</Label> : undefined,
    });
  }

  if (canSeeClubs) {
    items.push({
      title: translate('clubs'),
      path: paths.dashboard.clubs.root,
      icon: ICONS.clubs,
    });
  }

  if (canSeeCampaigns) {
    items.push({
      title: translate('campaigns'),
      path: paths.dashboard.campaigns.root,
      icon: ICONS.campaigns,
      info: runningCampaigns > 0 ? <Label color="error">{runningCampaigns}</Label> : undefined,
    });
  }

  if (canSeeBrands) {
    items.push({
      title: translate('brands'),
      path: paths.dashboard.brands.root,
      icon: ICONS.brands,
    });
  }

  return items;
};

const useChatItems = (newMessages: number, permissions: ListOfPermissionsAssignedToUser = {}) => {
  const { translate } = useLocales();

  const chatItems: NavItem[] = [];

  const canAccessChat = permissions.permissions?.some(
    ({ key = '', action }) =>
      ['MANAGE_ALL', 'CREATE_CONVERSATION'].includes(key) && action === 'allow'
  );

  chatItems.push({
    title: translate('news'),
    path: paths.dashboard.news.root,
    icon: ICONS.news,
  });

  if (canAccessChat) {
    chatItems.push({
      title: translate('chat'),
      path: paths.dashboard.chat.root,
      icon: ICONS.chat,
      info: newMessages > 0 ? <Label color="error">{newMessages}</Label> : undefined,
    });
  }

  return chatItems;
};
